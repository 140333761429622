import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick, plansPageView } from '@wix/bi-logger-membership/v2';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { IntegrationData } from '@wix/pricing-plans-router-utils';
import { getSetupFeeAmount, hasSetupFee } from '@wix/pricing-plans-utils';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { OrdersApi, PlansApi, PremiumApi } from '../../../services';
import { Analytics } from '../../../services/analytics';
import { isUuid } from '../../../services/uuid-compression';
import { WarmupData } from '../../../services/WarmupData';
import { ListProps, CommonProps, ModalType, noModal } from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { WIDGET_TYPE } from '../../../utils/bi';
import { toError } from '../../../utils/errors';
import { getOrderedPlans, getOrderedVisiblePlans } from '../../../utils/plan-visibility';
import { SettingsReader } from '../DefaultSettingsAdapter';
import { Router } from './Router';

export class ListController {
  constructor(
    protected setProps: (props: Partial<CommonProps & ListProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerFlowAPI,
    protected router: Router,
    protected plansApi: PlansApi,
    protected ordersApi: OrdersApi,
    protected premiumApi: PremiumApi,
    protected analytics: Analytics,
    protected settings: SettingsReader,
    protected demoPlans: PublicPlan[],
    protected warmupData: WarmupData,
    protected readonly hasMultiplePages: boolean,
  ) {}

  async initialize(integrationData: IntegrationData) {
    return this.update(integrationData);
  }

  async update(integrationData: IntegrationData, publicPlans?: PublicPlan[]) {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.ListPageLoaded);
    this.flowAPI.panoramaClient?.transaction(PackagePickerInteractions.ListPageLoaded).start();

    const { plans } = publicPlans
      ? { plans: this.orderPlans(publicPlans, integrationData) }
      : await this.warmupData.cache('list.plans', () => this.fetchAndOrderPlans(integrationData));

    if (this.flowAPI.environment.isViewer) {
      this.flowAPI.bi?.report(
        plansPageView({
          referralId: integrationData.biOptions?.referralId,
          referral_info: integrationData.biOptions?.referralInfo,
          widgetType: WIDGET_TYPE.page,
        }),
      );
    }
    this.analytics.addProductImpression(plans);

    this.setProps({
      overrideAppTitle: integrationData.title,
      overrideAppSubtitle: integrationData.subtitle,
      plans,
      selectPlan: (plan) => this.selectPlan(plan, integrationData),
      navigateToHomePage: () => this.router.gotoHomePage(),
    });
  }

  fetchAndOrderPlans = async (integrationData?: IntegrationData) => {
    const shouldValidatePlanIds = this.flowAPI.experiments.enabled(TPA_EXPERIMENTS.VALIDATE_PLAN_IDS);
    const integrationErrorContext = { contexts: { integrationData: { data: JSON.stringify(integrationData) } } };

    let plans: PublicPlan[] = [];

    const validPlanIds = integrationData?.planIds?.filter((planId) => isUuid(planId));

    if (integrationData?.planIds?.length !== validPlanIds?.length) {
      this.flowAPI.errorMonitor.captureException(new Error('Invalid plan ids'), integrationErrorContext);
    }

    const hasPlanIds = !!integrationData?.planIds?.length && integrationData.planIds.length > 0;
    const hasValidPlanIds = !!validPlanIds && validPlanIds.length > 0;

    if (shouldValidatePlanIds && hasPlanIds && !hasValidPlanIds) {
      return { plans: [] };
    }

    const planIds = shouldValidatePlanIds ? validPlanIds : integrationData?.planIds;

    try {
      const querySettings = planIds ? { include: planIds } : { exclude: this.settings.uuidAsArray('hiddenPlans') };
      plans = await this.plansApi.query(querySettings);
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e), integrationErrorContext);
    }

    return { plans: this.orderPlans(plans, integrationData) };
  };

  orderPlans = (plans: PublicPlan[], integrationData: IntegrationData | undefined): PublicPlan[] => {
    const useFixture = !plans.length && this.wixCodeApi.window.viewMode === 'Editor';
    return useFixture
      ? this.demoPlans
      : integrationData?.planIds
      ? getOrderedPlans(plans, this.settings)
      : getOrderedVisiblePlans(plans, this.settings);
  };

  protected selectPlan = async (plan: PublicPlan, integrationData: IntegrationData) => {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.PlanSelected);
    this.flowAPI.panoramaClient?.transaction(PackagePickerInteractions.PlanSelected).start();
    const planId = plan.id;

    if (!planId) {
      this.flowAPI.reportError('Plan has no id.');
      return;
    }

    this.setProps({ selectedPlanId: planId });
    if (this.wixCodeApi.window.viewMode !== 'Site') {
      this.setProps({
        modal: { type: ModalType.PurchaseInPreview, onClose: this.closeModal },
        selectedPlanId: undefined,
      });
      this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.PlanSelected);
      this.flowAPI.panoramaClient?.transaction(PackagePickerInteractions.PlanSelected).finish();
      return;
    }

    this.flowAPI.bi?.report(
      planPurchaseClick({
        planGuid: plan.id!,
        widgetType: 'page',
        setupFeeAmount: hasSetupFee(plan) ? Math.round(Number(getSetupFeeAmount(plan)) * 100) : undefined,
      }),
    );
    this.analytics.clickProduct(plan);

    this.router.gotoCheckout(plan, integrationData);

    this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.PlanSelected);
    this.flowAPI.panoramaClient?.transaction(PackagePickerInteractions.PlanSelected).finish();
  };

  private closeModal = () => this.setProps({ modal: noModal });
}
